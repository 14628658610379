import React from "react";
import { Switch, Route } from "react-router-dom";
import { publicRoutes } from "./../../routes/routes";

class PreLogin extends React.Component {
  render() {
    return (
      <Switch>
        {publicRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <route.component {...props} routes={route.routes} />
            )}
          />
        ))}
      </Switch>
    );
  }
}

export default PreLogin;
