import React from "react";
import { Layout } from "antd";
import PostLayoutHeader from "./Header";
import PostLayoutSider from "./Sider";
import "./PostLoginLayout.scss";

const { Content } = Layout;

class PostLoginLayout extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { onLogout } = this.props;
    return (
      <Layout className="post-login-layout-panel">
        <PostLayoutSider collapsed={this.state.collapsed} />
        <Layout className="site-layout">
          <PostLayoutHeader
            toggle={this.toggle}
            collapsed={this.state.collapsed}
            onLogout={onLogout}
          />
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default PostLoginLayout;
