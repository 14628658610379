import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import { observer, inject } from "mobx-react";

import siderMenu from "./siderMenu";

import { MailOutlined } from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;
@inject("authStore")
@observer
@withRouter
class PostLayoutSider extends React.Component {
  onOptionHover = (menu) => {
    menu.component.preload();
  };

  getCurrentKey = () => {
    const { location } = this.props;
    return siderMenu.find((menuOpt) => menuOpt.path === location.pathname);
  };

  render() {
    const { collapsed } = this.props;
    const { authStore } = this.props;
    const listItems = siderMenu.map((menu) => {
      if (menu.label === "Workspace Module") {
        return (
          <SubMenu key="sub1" icon={<MailOutlined />} title="Workspace Module">
            <Menu.Item
              key={menu.children[0].id}
              onMouseEnter={() => this.onOptionHover(menu.children[0])}
            >
              <Link to={menu.children[0].path}>
                {menu.icon}
                <span>{menu.children[0].label}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={menu.children[1].id}
              onMouseEnter={() => this.onOptionHover(menu.children[1])}
            >
              <Link to={menu.children[1].path}>
                {menu.icon}
                <span>{menu.children[1].label}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={menu.children[2].id}
              onMouseEnter={() => this.onOptionHover(menu.children[2])}
            >
              <Link to={menu.children[2].path}>
                {menu.icon}
                <span>{menu.children[2].label}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={menu.children[3].id}
              onMouseEnter={() => this.onOptionHover(menu.children[3])}
            >
              <Link to={menu.children[3].path}>
                {menu.icon}
                <span>{menu.children[3].label}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={menu.children[4].id}
              onMouseEnter={() => this.onOptionHover(menu.children[4])}
            >
              <Link to={menu.children[4].path}>
                {menu.icon}
                <span>{menu.children[4].label}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={menu.children[5].id}
              onMouseEnter={() => this.onOptionHover(menu.children[5])}
            >
              <Link to={menu.children[5].path}>
                {menu.icon}
                <span>{menu.children[5].label}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={menu.children[6].id}
              onMouseEnter={() => this.onOptionHover(menu.children[6])}
            >
              <Link to={menu.children[6].path}>
                {menu.icon}
                <span>{menu.children[6].label}</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        );
      } else {
        if (
          (!authStore.isUserRole || authStore.userData.workspace) &&
          menu.id === 10
        ) {
          return;
        } else {
          return (
            <Menu.Item
              key={menu.id}
              onMouseEnter={() => this.onOptionHover(menu)}
            >
              <Link to={menu.path}>
                {menu.icon}
                <span>{menu.label}</span>
              </Link>
            </Menu.Item>
          );
        }
      }
    });
    return (
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <Menu theme="dark" mode="inline">
          {listItems}
        </Menu>
      </Sider>
    );
  }
}

export default PostLayoutSider;
