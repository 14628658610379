import React from "react";
import { Switch, Route } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { privateRoutes } from "./../../routes/routes";
import Layout from "@Components/post-login-layout/PostLoginLayout";

import "./index.scss";

@inject("authStore")
@observer
class PostLogin extends React.Component {
  render() {
    const { authStore } = this.props;
    return (
      <Layout onLogout={() => authStore.logoutUser()}>
        <Switch>
          {privateRoutes.map((route) => (
            <Route
              key={route.id}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <route.component {...props} routes={route.routes} />
              )}
            />
          ))}
        </Switch>
      </Layout>
    );
  }
}

export default PostLogin;
