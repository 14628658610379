import React from "react";
import { observer } from "mobx-react";
import { Provider } from "mobx-react";
import store from "./stores/index.js";
import MainRoute from "@Pages";
import "./App.css";

@observer
class App extends React.Component {
  render() {
    return (
      <Provider {...store}>
        <MainRoute />
      </Provider>
    );
  }
}

export default App;
