import axios from "axios";
import { generateFormData } from "./helper";

class FetchBase {
  constructor() {
    this.configInterceptors();
  }

  configInterceptors = () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_DEV;

    axios.interceptors.request.use(
      (config) => {
        return this.requestHandler(config);
      },
      function (error) {
        console.log("Error in sending request: ", error);
        return Promise.reject(error);
      }
    );
  };

  requestHandler = (request) => {
    const tokenFromLs = localStorage.getItem("token");
    if (tokenFromLs) {
      request.headers.common["Authorization"] = "Bearer " + tokenFromLs;
    }
    return request;
  };

  _get = async (url, resType = "json") => {
    try {
      const response = await axios.get(url, {
        responseType: resType,
      });
      return response;
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  _post = async (url, payload) => {
    try {
      const response = await axios.post(url, payload);
      return response;
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  _postWithFormData = async (url, formData) => {
    try {
      // const formData = new FormData();
      // formData.append("avatar", "dd");
      
      const response = await axios.post(url, formData,{
        headers: {
          "content-type": "multipart/form-data"
        }
      });
      return response;
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  _patch = async (url, payload) => {
    try {
      const response = await axios.patch(url, payload);
      return response;
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  _delete = async (url, payload) => {
    try {
      const response = await axios.delete(url, payload);
      return response;
    } catch (e) {
      console.log("Error: ", e);
    }
  };
}

export default FetchBase;
