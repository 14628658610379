import React from "react";
import { observer, inject } from "mobx-react";
import { Layout, Avatar, Popover } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

const { Header } = Layout;
@inject("authStore")
@observer
class PostLayoutHeader extends React.Component {
  render() {
    const { authStore, toggle, collapsed, onLogout } = this.props;
    const isUserRole = authStore.isUserRole;
    const content = (
      <>
        <span>{isUserRole ? "Admin" : "SubAdmin"}</span>
        <div onClick={onLogout}>Logout</div>
      </>
    );

    return (
      <Header className="site-layout-background" style={{ padding: 0 }}>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: toggle,
          }
        )}
        <span style={{ float: "right", paddingRight: "20px" }}>
          <Popover placement="bottomLeft" content={content} trigger="click">
            <Avatar
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              size={40}
            />
          </Popover>
        </span>
      </Header>
    );
  }
}

export default PostLayoutHeader;
