import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";

class WorkspaceModuleStore extends FetchBase {
  @observable userManagementStoreUserData = JSON.parse(
    localStorage.getItem("userData")
  );
  @observable workspace = this.userManagementStoreUserData.workspace;
  @observable workspaceModuleData = [];
  @observable workspaceCategory = [];
  @observable workspaceLevel = [];
  @observable workspaceAnswerLevels = [];
  @observable workspaceQuestionLevels = [];
  @observable workspaceHelpfulLevels = [];
  @observable workspaceTransitionLevels = [];
  @observable workspaceTiebreakers = [];
  @observable tieBreakersId = null;

  @action.bound
  fetchWorkspaceDetails = async () => {
    try {
      const response = await this._get("/admin/workspace");
      if(response?.status==200){
        this.workspaceModuleData = response.data.data;
return [true,response.data.data]
      }
      return [false, []]
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  // Fetched Category
  @action.bound
  fetchWorkspaceCategory = async (payload) => {
    try {
      const response = await this._get(`admin/template?workspace=${payload}`);
      this.workspaceCategory = response.data.data;
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  // Update Categories isMaster
  @action.bound
  updateWorkspaceCategoriesIsMaster = async (
    workspaceId,
    isMaster,
    categoryID,
    payload
  ) => {
    try {
      let body = null;
      body = { isMaster: isMaster };
      Object.assign(body, { workspace: workspaceId });
      const response = await this._patch(`admin/template/${categoryID}`, body);
      toastr.success(response.data.message);

      const res = await this._get(`admin/template?workspace=${workspaceId}`);
      this.workspaceCategory = res.data.data;
      if (response.data.message === "updated") {
        return (this.workspaceUpdate = body.isMaster);
      } else {
        return (this.workspaceUpdate = "error");
      }
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Update Categories
  @action.bound
  updateWorkspaceCategories = async (workspaceId, payload) => {
    try {
      Object.assign(payload, { workspace: workspaceId });
      const response = await this._patch(
        `admin/template/${payload._id}`,
        payload
      );
      toastr.success(response.data.message);
      const res = await this._get(`admin/template?workspace=${workspaceId}`);
      this.workspaceCategory = res.data.data;
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Fetched Levels
  @action.bound
  fetchWorkspaceLevel = async (payload) => {
    try {
      const response = await this._get(`admin/template/${payload}?type=levels`);
      return (this.workspaceLevel = response.data.data[0].levels);
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  // Update WorkspaceLevel
  @action.bound
  updateWorkspaceLevel = async (categoryID, payload) => {
    try {
      let body = null;
      body = {
        workspace: this.workspace,
        name: payload.name,
        minGestionalAge: payload.minGestionalAge,
        maxGestionalAge: payload.maxGestionalAge,
        minBirthWeight: payload.minBirthWeight,
        maxBirthWeight: payload.maxBirthWeight,
        designationTheme: payload.designationTheme,
        description: payload.description,
        helpfulInformation: payload.helpfulInformation,
      };
      const response = await this._patch(
        `admin/template/${categoryID}/levels/${payload._id}`,
        body
      );
      toastr.success(response.data.message);

      const res = await this._get(`admin/template/${categoryID}?type=levels`);
      return (this.workspaceLevel = res.data.data[0].levels);
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Get answer levels details
  @action.bound
  fetchAnswerLevels = async (categoryID, payload) => {
    try {
      const response = await this._get(
        `admin/template/${categoryID}/levels/${payload}/answer`
      );
      return (this.workspaceAnswerLevels = response.data.data);
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  // Update answer levels designated
  @action.bound
  updateWorkspaceDesignated = async (
    designated,
    payload,
    categoryID,
    levelID
  ) => {
    try {
      let body = null;
      body = { designated: designated, workspace: this.workspace };

      const response = await this._patch(
        `admin/template/${categoryID}/levels/${levelID}/answer/${payload._id}`,
        body
      );
      toastr.success(response.data.message);

      const res = await this._get(
        `admin/template/${categoryID}/levels/${levelID}/answer`
      );
      return (this.workspaceAnswerLevels = res.data.data);
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Update AnswerLevels
  @action.bound
  updateAnswerLevels = async (categoryID, levelID, payload) => {
    try {
      let body = null;
      body = {
        workspace: this.workspace,
        rule: payload.rule,
        nextLevelName: payload.nextLevelName,
      };

      const response = await this._patch(
        `admin/template/${categoryID}/levels/${levelID}/answer/${payload._id}`,
        body
      );
      toastr.success(response.data.message);

      const res = await this._get(
        `admin/template/${categoryID}/levels/${levelID}/answer`
      );
      return (this.workspaceAnswerLevels = res.data.data);
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Get question levels details
  @action.bound
  fetchQuestionLevels = async (categoryID, payload) => {
    try {
      const response = await this._get(
        `admin/template/${categoryID}/levels/${payload}/questions`
      );
      return (this.workspaceQuestionLevels = response.data.data);
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  // Update update Question is Designator
  @action.bound
  updateQuestionisDesignator = async (
    isDesignator,
    payload,
    categoryID,
    levelID
  ) => {
    try {
      let body = null;
      body = { isDesignator: isDesignator, workspace: this.workspace };

      const response = await this._patch(
        `admin/template/${categoryID}/levels/${levelID}/questions/${payload._id}`,
        body
      );
      toastr.success(response.data.message);

      const res = await this._get(
        `admin/template/${categoryID}/levels/${levelID}/questions`
      );
      return (this.workspaceQuestionLevels = res.data.data);
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Update Question Levels
  @action.bound
  updateQuestionLevels = async (categoryID, levelID, payload) => {
    try {
      let body = null;
      body = {
        workspace: this.workspace,
        order: payload.order,
        description: payload.description,
      };

      const response = await this._patch(
        `admin/template/${categoryID}/levels/${levelID}/questions/${payload._id}`,
        body
      );
      toastr.success(response.data.message);

      const res = await this._get(
        `admin/template/${categoryID}/levels/${levelID}/questions`
      );
      return (this.workspaceQuestionLevels = res.data.data);
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Get Helpful Levels details
  @action.bound
  fetchHelpfulLevels = async (categoryID, payload) => {
    try {
      const response = await this._get(
        `admin/template/${categoryID}/levels/${payload}/helpful`
      );
      return (this.workspaceHelpfulLevels = response.data.data);
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  // Update Helpful Levels
  @action.bound
  updateHelpfulLevels = async (categoryID, levelID, payload) => {
    try {
      let body = null;
      body = {
        workspace: this.workspace,
        fromDaysInColor: payload.fromDaysInColor,
        toDaysInColor: payload.toDaysInColor,
        designationTheme: payload.designationTheme,
        helpfulInformation: payload.helpfulInformation,
      };

      const response = await this._patch(
        `admin/template/${categoryID}/levels/${levelID}/helpful/${payload._id}`,
        body
      );
      toastr.success(response.data.message);

      const res = await this._get(
        `admin/template/${categoryID}/levels/${levelID}/helpful`
      );
      return (this.workspaceHelpfulLevels = res.data.data);
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Get Transition Levels details
  @action.bound
  fetchTransitionLevels = async (categoryID, payload) => {
    try {
      const response = await this._get(
        `admin/template/${categoryID}/levels/${payload}/transition`
      );
      return (this.workspaceTransitionLevels = response.data.data);
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  // Update Transition Levels
  @action.bound
  updateTransitionLevels = async (categoryID, levelID, payload) => {
    try {
      let body = null;
      body = {
        workspace: this.workspace,
        toLevel: payload.toLevel,
        helpfulInformation: payload.helpfulInformation,
      };

      const response = await this._patch(
        `admin/template/${categoryID}/levels/${levelID}/transition/${payload._id}`,
        body
      );
      toastr.success(response.data.message);

      const res = await this._get(
        `admin/template/${categoryID}/levels/${levelID}/transition`
      );
      return (this.workspaceTransitionLevels = res.data.data);
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // WorkspaceModuleTiebreakers
  @action.bound
  fetchWorkspaceTiebreakers = async (payload, level) => {
    let LevelList = [];
    try {
      const response = await this._get(
        `admin/template/${payload}?type=tieBreakers`
      );
      if (level === "first") {
        const Level = response.data.data[0].tieBreakers.map((res, i) => {
          let tbID = res._id;
          let _id = res.firstLevel._id;
          let levelName = res.firstLevel.levelName;
          let statement = res.firstLevel.statement;
          LevelList.push({ tbID, _id, levelName, statement });
        });
        return (this.workspaceTiebreakers = LevelList);
      } else if (level === "second") {
        const Level = response.data.data[0].tieBreakers.map((res, i) => {
          let tbID = res._id;
          let _id = res.secondLevel._id;
          let levelName = res.secondLevel.levelName;
          let statement = res.secondLevel.statement;
          LevelList.push({ tbID, _id, levelName, statement });
        });
        return (this.workspaceTiebreakers = LevelList);
      }
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  // Update Transition Levels
  @action.bound
  updateTiebreakers = async (categoryID, selectTieBreakersLevel, payload) => {
    let LevelList = [];
    try {
      let body = null;
      body = {
        workspace: this.workspace,
        levelName: payload.levelName,
        statement: payload.statement,
      };

      const res = await this._patch(
        `admin/template/${categoryID}/tiebreakers/${payload.tbID}/${selectTieBreakersLevel}`,
        body
      );
      toastr.success(res.data.message);

      const response = await this._get(
        `admin/template/${categoryID}?type=tieBreakers`
      );
      if (selectTieBreakersLevel === "first") {
        const Level = response.data.data[0].tieBreakers.map((res, i) => {
          let tbID = res._id;
          let _id = res.firstLevel._id;
          let levelName = res.firstLevel.levelName;
          let statement = res.firstLevel.statement;
          LevelList.push({ tbID, _id, levelName, statement });
        });
        return (this.workspaceTiebreakers = LevelList);
      } else if (selectTieBreakersLevel === "second") {
        const Level = response.data.data[0].tieBreakers.map((res, i) => {
          let tbID = res._id;
          let _id = res.secondLevel._id;
          let levelName = res.secondLevel.levelName;
          let statement = res.secondLevel.statement;
          LevelList.push({ tbID, _id, levelName, statement });
        });
        return (this.workspaceTiebreakers = LevelList);
      }
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };
}

export default WorkspaceModuleStore;
