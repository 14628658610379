import { observable, action } from "mobx"; 
import FetchBase from "@Services/FetchBase";
import history from "@Services/History";
import toastr from "@Services/Message";
import {
  USER_ROLES
} from "@Services/Constants";

class AuthStore extends FetchBase {
  @observable isUserAuthenticated = false;
  @observable isUserRole = false; 
  @observable userData = "";

  @action.bound
  authenticateUser = async (payload) => {
    const loginLoader = toastr.loading("Logging in..");
    try {
      const response = await this._post("/admin/auth", { payload });
      if (response.data) {
        toastr.cancelLoading(loginLoader, 1);
        toastr.success("Logged in successfully!");
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem(
          "userData",
          JSON.stringify(response.data.data.user)
        );

        if (response.data.data.user.role === 1) {
          if (response.data.data.user.workspace) {
            localStorage.setItem("role", USER_ROLES.workspaceSuperAdmin);
          } else {
            localStorage.setItem("role", USER_ROLES.superAdmin);
          }
        } else if (response.data.data.user.role === 2) {
          localStorage.setItem("role", USER_ROLES.subAdmin);
        } else {
          localStorage.setItem("role", USER_ROLES.user);
        }
        this.validateTokenAndLogin();
      }
    } catch (e) {
      toastr.cancelLoading(loginLoader, 1);
      toastr.error("Invalid credentials!");
    }
  };

  @action.bound
  validateTokenAndLogin = () => {
    const tokenFromLs = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const isRole = localStorage.getItem("role");
    this.userData = userInfo;
    if (tokenFromLs && tokenFromLs.length) {
      this.isUserAuthenticated = true;
      if (isRole === USER_ROLES.superAdmin || isRole === USER_ROLES.workspaceSuperAdmin) {
        this.isUserRole = true;
      } else {
        this.isUserRole = false;
      }
    } else {
      this.isUserAuthenticated = false;
    }
  };

  @action.bound
  logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("userData");

    this.isUserAuthenticated = false;
    this.isUserRole = false;
    this.userData = null;
    history.push("/");
  };
}

export default AuthStore;
