import React from "react";
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  SettingOutlined,
  NotificationOutlined,
  ThunderboltOutlined
} from "@ant-design/icons";

import {
  Dashboard,
  UserManagement,
  PassportManagement,
  CmsPage,
  PushNotificationPage,
  ProfileSettingsPage,
  AdminManageSetting,
  MessageByColor,
  VideoLink,
  ExportTemplate,
  MusicLink,
  MilestoneConfig
} from "@Routes/routes";
 

export default [
  {
    id: 1,
    label: "Dashboard",
    path: "/",
    icon: <UserOutlined />,
    component: Dashboard,
  },
  {
    id: 2,
    label: "User Management",
    path: "/user-management",
    icon: <VideoCameraOutlined />,
    component: UserManagement,
  },
  {
    id: 3,
    label: "Baby management",
    path: "/baby-management",
    icon: <UploadOutlined />,
    component: PassportManagement,
  },
  {
    id: 4,
    label: "Workspace",
    path: "/workspace",
    icon: <SettingOutlined />,
    component: ProfileSettingsPage,
  },
  {
    id: 5,
    label: "Workspace Module",
    path: "/workspace-module",
    icon: <SettingOutlined />,
    children: [
      {
        id: 9_1,
        label: "Categories",
        path: "/workspace-module-categories",
        component: ProfileSettingsPage,
      },
      {
        id: 9_2,
        label: "Levels",
        path: "/workspace-module-levels",
        component: ProfileSettingsPage,
      },
      {
        id: 9_3,
        label: "Answer Levels",
        path: "/workspace-module-answer-levels",
        component: ProfileSettingsPage,
      },
      {
        id: 9_4,
        label: "Question Levels",
        path: "/workspace-module-question-levels",
        component: ProfileSettingsPage,
      },
      {
        id: 9_5,
        label: "Helpful levels",
        path: "/workspace-module-helpful-levels",
        component: ProfileSettingsPage,
      },
      {
        id: 9_6,
        label: "Transition Levels",
        path: "/workspace-module-transition-levels",
        component: ProfileSettingsPage,
      },
      {
        id: 9_7,
        label: "Tiebreakers",
        path: "/workspace-module-tiebreakers",
        component: ProfileSettingsPage,
      },
    ],
  },
  {
    id: 6,
    label: "Message By Color",
    path: "/message-by-color",
    icon: <UploadOutlined />,
    component: MessageByColor,
  },
  {
    id: 7,
    label: "User History",
    path: "/user-history",
    icon: <UploadOutlined />,
    component: PassportManagement,
  },
  {
    id: 8,
    label: "Video Links",
    path: "/video-link",
    icon: <UploadOutlined />,
    component: VideoLink,
  },
  {
    id: 9,
    label: "Music Link",
    path: "/music-link",
    icon: <UploadOutlined />,
    component: MusicLink,
  },
  {
    id: 10,
    label: "Push Notifications",
    path: "/push-notifications",
    icon: <NotificationOutlined />,
    component: PushNotificationPage,
  },
  {
    id: 11,
    label: "Template Export",
    path: "/export-template",
    icon: <UploadOutlined />,
    component: ExportTemplate,
  },
  {
    id: 12,
    label: "Milestone",
    path: "/milestone-config",
    icon: <ThunderboltOutlined />,
    component: MilestoneConfig,
  },
  {
    id: 13,
    label: "Setting",
    path: "/admin-manage",
    icon: <SettingOutlined />,
    component: AdminManageSetting,
  },
];
