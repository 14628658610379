import React from "react";
import { message } from "antd";

const DEFAULT_TIMEOUT = 3;
const LOADING_TIMEOUT = 0;
const LOADER_TIMEOUT = 1;

export default {
  success: (msg, time = DEFAULT_TIMEOUT) => message.success(msg, time),
  error: (msg, time = DEFAULT_TIMEOUT) => message.error(msg, time),
  loading: (msg, time = LOADING_TIMEOUT) => message.loading(msg, time),
  warning: (msg, time = DEFAULT_TIMEOUT) => message.warning(msg, time),
  cancelLoading: (loader, time = LOADER_TIMEOUT) => setTimeout(loader, time),
};
