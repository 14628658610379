import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";

class PassportManagementStore extends FetchBase {
  @observable userManagementStoreUserData = JSON.parse(
    localStorage.getItem("userData")
  );
  @observable workspace = this.userManagementStoreUserData.workspace;
  @observable passportList = [];
  @observable userHistoryList = [];

  @action.bound
  fetchPassportList = async () => {
    try {
      const response = await this._get(`admin/baby`);
      this.passportList = response.data.data;
    } catch (e) {
      console.log("Error store:", e);
    }
  };

  @action.bound
  deletePassportDetails = async (payload) => {
    try {
      const res = await this._delete(`admin/baby/${payload}`, {
        data: { workspace: this.workspace },
      });
      toastr.success(res.data.message);
      const response = await this._get(`admin/baby`);
      this.passportList = response.data.data;
    } catch (e) {
      console.log("Error Store :", e);
    }
  };

  // user History
  @action.bound
  fetchUserHistoryList = async (fromDate, toDate) => { 
    try {
      const response = await this._get(`admin/user-history?to=${toDate}&from=${fromDate}`);
      this.userHistoryList = response.data.data;
    } catch (e) {
      console.log("Error store:", e);
    }
  };
}

export default PassportManagementStore;
