import AuthStore from "./AuthStore";
import DashboardStore from "./DashboardStore";
import UserManagementStore from "./UserManagementStore";
import PushNotificationStore from "./PushNotificationStore";
import WorkspaceStore from "./WorkspaceStore";
import WorkspaceModuleStore from "./WorkspaceModuleStore";
import PassportManagementStore from "./PassportManagementStore";
import MessageByColorStore from "./MessageByColorStore";
import ExportTemplateStore from "./ExportTemplateStore";
import MusicLinkStore from "./MusicLinkStore";
import MilestoneConfigStore from "./MilestoneConfigStore";

export default {
  authStore: new AuthStore(),
  dashboardStore: new DashboardStore(),
  userManagementStore: new UserManagementStore(),
  notificationStore: new PushNotificationStore(),
  workspaceStore: new WorkspaceStore(),
  workspaceModuleStore: new WorkspaceModuleStore(),
  passportManagementStore: new PassportManagementStore(),
  messageByColorStore: new MessageByColorStore(),
  exportTemplateStore: new ExportTemplateStore(),
  musicLinkStore: new MusicLinkStore(),
  milestoneConfigStore: new MilestoneConfigStore(),
};
