import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";
import { generateFormData } from "../services/helper";

class MilestoneConfigStore extends FetchBase {
  @observable milestoneConfigList = [];

  @action.bound
  fetchMileStoneConfigList = async (workspace_id) => {
    try {
      const response = await this._get(`admin/milestone-config/list?workspace=${workspace_id}`);
      this.milestoneConfigList = response.data.data;
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  @action.bound
  addNewMilestone = async (values) => {
    try {
      const formData = await generateFormData(values);
      const response = await this._postWithFormData(
        "admin/milestone-config/add",
        formData
      );
      toastr.success(response.data.message);
      return { status: true }
    } catch (e) {
      toastr.error("Sorry, This email id already exists");
      console.log("Error store :", e);
      return { status: false }
    }
  };

  @action.bound
  updateMilestone = async (formData, id) => {
    try {
      const body = { ...formData };
      const response = await this._patch(
        `admin/milestone-config/${id}/update`,
        body
      );

      toastr.success(response.data.message);
      return { status: true }
    } catch (e) {
      toastr.error("Sorry, This email id already exists");
      console.log("Error store :", e);
    }
  };

  @action.bound
  deleteMilestoneConfig = async (formData) => {
    try {
      const response = await this._delete(
        `admin/milestone-config/${formData?.id}`,
      );
      toastr.success(response.data.message);
      return { status: true }
    } catch (e) {
      toastr.error("Cannot Delete/Block logged in user ");
      console.log("Error Store", e);
    }
  };
}

export default MilestoneConfigStore;
