import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";
import history from "@Services/History";

class UserManagementStore extends FetchBase {
  @observable workspaceName = [];
  @observable ListOfUser = [];
  @observable fromDate = null;
  @observable toDate = null;
  @observable btnLoading = false;

  @action.bound
  fetchWorkspaceDetails = async () => {
    try {
      const response = await this._get("/admin/workspace");
      this.workspaceName = response.data.data;
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  @action.bound
  fetchAllUser = async (fromDate, toDate) => {
    try {
      this.fromDate = fromDate;
      this.toDate = toDate;
      const res = await this._get(
        `admin/user-management/get-all?to=${toDate}&from=${fromDate}&history=true`
      );
      this.ListOfUser = res.data.data;
    } catch (e) {
      console.log("Error Store:", e);
    }
  };

  @action.bound
  addNewUser = async (formData) => {
    try {
      const body = { payload: formData };

      const response = await this._post(
        `admin/user-management/create-user`,
        body
      );
      toastr.success(response.data.message);

      const res = await this._get(
        `admin/user-management/get-all?to=${this.toDate}&from=${this.fromDate}&history=true`
      );
      this.ListOfUser = res.data.data;
    } catch (e) {
      toastr.error("Sorry, This email id already exists");
      console.log("Error store :", e);
    }
  };

  @action.bound
  deleteUserDetails = async (formData) => {
    try {
      const response = await this._delete(
        `admin/user-management/delete-user/${formData._id}`,
        { data: { payload: formData } }
      );
      toastr.success(response.data.message);

      const res = await this._get(
        `admin/user-management/get-all?to=${this.toDate}&from=${this.fromDate}&history=true`
      );
      this.ListOfUser = res.data.data;
    } catch (e) {
      toastr.error("Cannot Delete/Block logged in user ");
      console.log("Error Store", e);
    }
  };

  @action.bound
  userIsActive = async (payload, isUpdate) => {
    try {
      let body = null;
      const response = await this._patch(
        `admin/user-management/block-user/${payload._id}?isActive=${isUpdate}`,
        { workspace: payload.workspace }
      );
      toastr.success(response.data.message);

      const res = await this._get(
        `admin/user-management/get-all?to=${this.toDate}&from=${this.fromDate}&history=true`
      );
      this.ListOfUser = res.data.data;
    } catch (e) {
      toastr.error("Cannot Delete/Block logged in user ");
      console.log("Error Store :", e);
    }
  };

  @action.bound
  adminCreate = async (payload) => {
    try {
      this.btnLoading = true; 
      const response = await this._post(`admin/workspace-admin/`, payload);

      toastr.success(response.data.message);
    } catch (e) {
      toastr.error("Sorry, Email id already exists!");
      console.log("Error store :", e);
    } finally {
      this.btnLoading = false;
    }
  };
}

export default UserManagementStore;
