import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";
import history from "@Services/History";

class ExportTemplateStore extends FetchBase {
  @observable sendingNotifications = false;

  @action.bound
  exportTemplate = async (payload) => {
    try {
      const response = await this._get(
        `/admin/export-xlsx/${payload.workspaceId}`,
        'arraybuffer'
      );
      toastr.success('Success...');
      return response;
    } catch (e) {
      console.log("Error store: ", e);
      toastr.error("Error in exporting template. Try again!");
    }
  };
}

export default ExportTemplateStore;
