import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";
import { API_SUCCESS } from "@Services/Constants.js";
import { API_RES_MESSAGE } from "../services/Constants";
import { generateFormData } from "../services/helper";

class MusicLinkStore extends FetchBase {
  @observable musicList = [];
  @observable addbtnLoading =false
  //------------------------------------------------ Music List Page ------------------------------------------------
  @action.bound
  getMusicList = async (workspaceId) => {
    try {
      const response = await this._get(`/admin/music-link/get-all/`);
      this.musicList = response?.data?.data || [];
    } catch (err) {
      console.log(err);
    }
  };

  @action.bound
  addMusicLinkDetails = async (values) => {
    try {
      this.addbtnLoading = true
      const formData = await generateFormData(values);
      const response = await this._postWithFormData(
        "admin/music-link/upload",
        formData
      );
      if (response?.status === API_SUCCESS) {
        toastr.success(response.data.message);
        this.addbtnLoading = false
        return [true, response?.data];
      }
      toastr.error(API_RES_MESSAGE?.messageByMusicLinkResFail);
      this.addbtnLoading = false
      return [true, undefined];
    } catch (err) {
      toastr.error(API_RES_MESSAGE?.messageByMusicLinkResFail);
      this.addbtnLoading = false
      return [false, undefined];
    }
  };

 

  @action.bound
  deleteMusicLink = async (formData) => {
    try {
      const response = await this._delete(
        `admin/music-link/delete/${formData?._id}`
      );
      toastr.success(response?.data?.message);
      return response?.status;
    } catch (e) {
      toastr.error("Sorry, Please try again");
      console.log("Error store :", e);
    }
  };
}

export default MusicLinkStore;
