import React, { Suspense } from "react";
import { observer, inject } from "mobx-react";
import { BrowserRouter as Router } from "react-router-dom";

import PreloginRoutes from "@Prelogin";
import PostloginRoutes from "@Postlogin";

import "@Scss/colors.scss";
@inject("authStore")
@observer
class MainRoute extends React.Component {
  state = { loggedin: false };
  constructor(props) {
    super();
    const { authStore } = props;
    authStore.validateTokenAndLogin();
  }

  render() {
    const { authStore } = this.props;
    return (
      <Suspense fallback="...loading">
        <Router>
          {authStore.isUserAuthenticated ? (
            <PostloginRoutes />
          ) : (
            <PreloginRoutes />
          )}
        </Router>
      </Suspense>
    );
  }
}

export default MainRoute;
