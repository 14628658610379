import React from "react";
import { Spin } from "antd";

class PageLoader extends React.Component {
  render() {
    return (
      <div>
        <Spin
          size="large"
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      </div>
    );
  }
}

export default PageLoader;
