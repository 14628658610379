import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";
import history from "@Services/History";

class PushNotificationStore extends FetchBase {
  @observable sendingNotifications = false;

  @action.bound
  sendPushNotifications = async (payload) => {
    try {
      this.sendingNotifications = true;
      const response = await this._post(
        `/admin/push-notification${
          payload.workspaceId === "all" ? "" : `/${payload.workspaceId}`
        }`,
        payload
      );
      toastr.success(response.data.message);
      this.sendingNotifications = false;
      return response;
    } catch (e) {
      console.log("Error store: ", e);
      toastr.error("Error in sending notification. Try again!");
      this.sendingNotifications = false;
    }
  };
}

export default PushNotificationStore;
