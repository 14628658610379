import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";
import { API_SUCCESS } from "@Services/Constants.js";
import { API_RES_MESSAGE } from "../services/Constants";

class WorkspaceStore extends FetchBase {
  @observable workspaceName = [];
  @observable videList = [];

  @action.bound
  fetchWorkspaceDetails = async () => {
    try {
      const response = await this._get("/admin/workspace");
      this.workspaceName = response.data.data;
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  @action.bound
  addWorkspace = async (payload) => {
    try {
      const body = { code: payload };
      const response = await this._post("/admin/workspace", body);
      toastr.success(response.data.message);
      const res = await this._get("/admin/workspace");
      this.workspaceName = res.data.data;
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Import File
  @action.bound
  ImportWorkspaceFile = async (payload) => {
    try {
      var data = new FormData();
      data.append("file", payload.upload[0].originFileObj);
      data.set("workspace", payload._id);
      data.set("type", "template");
      data.set("value", payload._id);

      const response = await this._post("/admin/template-import", data);
      toastr.success(response.data.message);

      const ress = await this._get("/admin/template");
      const res = await this._get("/admin/workspace");
      this.workspaceName = res.data.data;
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  // Update Workspace
  @action.bound
  updateWorkspaceStatus = async (payload, update) => {
    try {
      let body = null;
      if (update === "updateCode") {
        body = { code: payload.code };
      } else if (payload.expired === true) {
        body = { expired: false };
      } else {
        body = { expired: true };
      }

      const response = await this._patch(
        `/admin/workspace/${payload._id}`,
        body
      );
      toastr.success(response.data.message);
      const res = await this._get("/admin/workspace");
      this.workspaceName = res.data.data;
      if (response.data.message === "updated") {
        return (this.workspaceUpdate = body.expired);
      } else {
        return (this.workspaceUpdate = "error");
      }
    } catch (e) {
      console.log("Error workspace Update: ", e);
    }
  };

  //------------------------------------------------ Video List Page ------------------------------------------------
  @action.bound
  getVideoList = async (workspaceId) => {
    try {
      const response = await this._get(
        `/admin/video-link/get-all/?workspace=${workspaceId}`
      );
      this.videList = response?.data?.data || [];
    } catch (err) {
      console.log(err);
    }
  };

  @action.bound
  addVideoLinkDetails = async (values) => {
    try {
      const response = await this._post("admin/video-link/create/", {
        ...values,
      });
      if (response?.status === API_SUCCESS) {
        toastr.success(response.data.message);
        return [true, response?.data];
      }
      toastr.error(API_RES_MESSAGE?.messageByVideoLinkResFail);
      return [true, undefined];
    } catch (err) {
      toastr.error(API_RES_MESSAGE?.messageByVideoLinkResFail);
      return [false, undefined];
    }
  };

  @action.bound
  updateVideoLinkDetails = async (id, values) => {
    try {
      delete values.category;
      delete values.color;
      const response = await this._patch(`admin/video-link/update/`, {
        ...values,
        id,
      });
      if (response?.status === API_SUCCESS) {
        toastr.success(response?.data?.message);
        return [true, response?.data];
      }
      toastr.error(API_RES_MESSAGE?.messageByVideoLinkResFail);
      return [false, undefined];
    } catch (e) {
      toastr.error(API_RES_MESSAGE?.messageByVideoLinkResFail);
      return [false, undefined];
    }
  };

  @action.bound
  deleteVideoLink = async (formData) => {
    try {
      const response = await this._delete(`admin/video-link/delete`, {
        data: { id: formData?._id },
      });
      toastr.success(response?.data?.message);
      return response?.status;
    } catch (e) {
      toastr.error("Sorry, Please try again");
      console.log("Error store :", e);
    }
  };
}

export default WorkspaceStore;
