export const API_SUCCESS = 200;
export const IS_BLOCK = "Block";
export const IS_UNBLOCK = "Unblock";
export const IS_EXPIRED = "Expired";
export const IS_UNEXPIRED = "Unexpired";
export const IS_POPCONFIRM_MESSAGE = "Are you sure, you wants to";

export const IS_TRUE = "True";
export const IS_FALSE = "False";
export const IS_POPCONFIRM_TRUE_FALSE_MESSAGE = "Are you sure, you want to";

export const USER_ROLES = {
  superAdmin: "superAdmin",
  workspaceSuperAdmin: "workspaceSuperAdmin",
  subAdmin: "subAdmin",
  user: "user",
};

export const API_RES_MESSAGE = {
  messageByColorResFail: 'The message already exists for the same Workspace, category and color.',
  messageByVideoLinkResFail: 'The message already exists for the same Video details, category and color.',
  messageByMusicLinkResFail: 'The message already exists for the same Music details, category and color.'
}

export const USER_HISTORY_FILTER = [
  {
    text: 'PURPLE',
    value: 'PURPLE',
  },
  {
    text: 'BLUE',
    value: 'BLUE',
  },
  {
    text: 'ORANGE',
    value: 'ORANGE',
  },
  {
    text: 'YELLOW',
    value: 'YELLOW',
  },
]

export const WORKSPACE_CATEGORY_FILTER = [
  {
    id: 1,
    text: 'Breathing',
    value: 'breathing',
  },
  {
    id: 2,
    text: 'Eating',
    value: 'eating',
  },
  {
    id: 3,
    text: 'Temperature',
    value: 'temperature',
  },
  {
    id: 4,
    text: 'Sleeping',
    value: 'sleeping',
  },
  {
    id: 5,
    text: 'Growth',
    value: 'growth',
  },
]

export const COLOR_FILTER = [
  {
    id: 1,
    text: 'PURPLE',
    value: 'PURPLE',
  },
  {
    id: 2,
    text: 'BLUE',
    value: 'BLUE',
  },
  {
    id: 3,
    text: 'ORANGE',
    value: 'ORANGE',
  },
  {
    id: 4,
    text: 'YELLOW',
    value: 'YELLOW',
  },
]

export const globalWorkspace = "global"
export const milestoneType = {
  option: "option",
  video: "video"
}

