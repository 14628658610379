import React from "react";
import Loadable from "react-loadable";
import LoadingPageSpinner from "@Components/page-loader/PageLoader";

// const Login = React.lazy(() => import("@Prelogin/login/Login.js"));
export const Login = Loadable({
  loader: () => import("@Prelogin/login/Login.js"),
  loading: () => <LoadingPageSpinner />,
});

export const ForgotPassword = Loadable({
  loader: () => import("@Prelogin/forgot-password/ForgotPassword.js"),
  loading: () => <LoadingPageSpinner />,
});

export const Dashboard = Loadable({
  loader: () => import("@Postlogin/dashboard/Dashboard.js"),
  loading: () => <LoadingPageSpinner />,
});

export const UserManagement = Loadable({
  loader: () => import("@Postlogin/user-management/UserManagement.js"),
  loading: () => <LoadingPageSpinner />,
});

export const PassportManagement = Loadable({
  loader: () => import("@Postlogin/passport-management/PassportManagement.js"),
  loading: () => <LoadingPageSpinner />,
});

export const MessageByColor = Loadable({
  loader: () => import("@Postlogin/message-by-color/MessageByColor.js"),
  loading: () => <LoadingPageSpinner />,
});

export const UserHistory = Loadable({
  loader: () => import("@Postlogin/user-history/UserHistory.js"),
  loading: () => <LoadingPageSpinner />,
});

export const VideoLink = Loadable({
  loader: () => import("@Postlogin/video-link/VideoLink.js"),
  loading: () => <LoadingPageSpinner />,
});

export const MusicLink = Loadable({
  loader: () => import("@Postlogin/music-link/MusicLink.js"),
  loading: () => <LoadingPageSpinner />,
});

export const CmsPage = Loadable({
  loader: () => import("@Postlogin/cms/CmsPage.js"),
  loading: () => <LoadingPageSpinner />,
});

export const PushNotificationPage = Loadable({
  loader: () => import("@Postlogin/push-notifications/PushNotifications.js"),
  loading: () => <LoadingPageSpinner />,
});

export const ExportTemplate = Loadable({
  loader: () => import("@Postlogin/export-template/ExportTemplate.js"),
  loading: () => <LoadingPageSpinner />,
});

export const ProfileSettingsPage = Loadable({
  loader: () => import("@Postlogin/profile-settings/ProfileSettings.js"),
  loading: () => <LoadingPageSpinner />,
});

export const WorkSpace = Loadable({
  loader: () => import("@Postlogin/workspace/WorkSpace.js"),
  loading: () => <LoadingPageSpinner />,
});

export const WorkspaceModuleCategory = Loadable({
  loader: () =>
    import(
      "@Postlogin/workspace-modules-categories/WorkspaceModuleCategories.js"
    ),
  loading: () => <LoadingPageSpinner />,
});

export const WorkspaceModuleLevels = Loadable({
  loader: () =>
    import("@Postlogin/workspace-modules-levels/WorkspaceModuleLevels.js"),
  loading: () => <LoadingPageSpinner />,
});

export const AnswerLevels = Loadable({
  loader: () => import("@Postlogin/workspace-modules-levels/AnswerLevels.js"),
  loading: () => <LoadingPageSpinner />,
});

export const QuestionLevels = Loadable({
  loader: () => import("@Postlogin/workspace-modules-levels/QuestionLevels.js"),
  loading: () => <LoadingPageSpinner />,
});

export const HelpfulLevels = Loadable({
  loader: () => import("@Postlogin/workspace-modules-levels/HelpfulLevels.js"),
  loading: () => <LoadingPageSpinner />,
});

export const TransitionLevels = Loadable({
  loader: () =>
    import("@Postlogin/workspace-modules-levels/TransitionLevels.js"),
  loading: () => <LoadingPageSpinner />,
});

export const WorkspaceModuleTiebreakers = Loadable({
  loader: () =>
    import(
      "@Postlogin/workspace-modules-tiebreakers/WorkspaceModuleTiebreakers.js"
    ),
  loading: () => <LoadingPageSpinner />,
});

export const AdminManageSetting = Loadable({
  loader: () => import("@Postlogin/admin-manage-setting/AdminManageSetting.js"),
  loading: () => <LoadingPageSpinner />,
});

export const MilestoneConfig = Loadable({
  loader: () => import("@Postlogin/milestone-config/MilestoneConfig.js"),
  loading: () => <LoadingPageSpinner />,
});

export const NoMatch = Loadable({
  loader: () => import("@Components/no-match-route/NoMatchRoute"),
  loading: () => <LoadingPageSpinner />,
});

export const publicRoutes = [
  {
    id: 1_1,
    path: "/login",
    type: "public",
    exact: true,
    component: Login,
  },
  {
    id: 1_2,
    path: "/",
    type: "public",
    exact: true,
    component: Login,
  },
  {
    id: 1_4,
    path: "/forgot-password",
    type: "public",
    exact: true,
    component: ForgotPassword,
  },
  {
    id: 1_3,
    path: "*",
    type: "public",
    component: NoMatch,
  },
];

export const privateRoutes = [
  {
    id: 2_1,
    path: "/dashboard",
    type: "private",
    exact: true,
    component: Dashboard,
  },
  {
    id: 2_2,
    path: "/",
    type: "private",
    exact: true,
    component: Dashboard,
  },
  {
    id: 2_3,
    path: "/user-management",
    type: "private",
    exact: true,
    component: UserManagement,
  },
  {
    id: 2_4,
    path: "/baby-management",
    type: "private",
    exact: true,
    component: PassportManagement,
  },
  {
    id: 2_5,
    path: "/user-history",
    type: "private",
    exact: true,
    component: UserHistory,
  },
  {
    id: 2_6,
    path: "/content-management",
    type: "private",
    exact: true,
    component: CmsPage,
  },
  {
    id: 2_7,
    path: "/push-notifications",
    type: "private",
    exact: true,
    component: PushNotificationPage,
  },
  {
    id: 2_8,
    path: "/profile-settings",
    type: "private",
    exact: true,
    component: ProfileSettingsPage,
  },
  {
    id: 2_9,
    path: "/workspace",
    type: "private",
    exact: true,
    component: WorkSpace,
  },
  {
    id: 2_8_1,
    path: "/workspace-module-categories",
    type: "private",
    exact: true,
    component: WorkspaceModuleCategory,
  },
  {
    id: 2_8_2,
    path: "/workspace-module-levels",
    type: "private",
    exact: true,
    component: WorkspaceModuleLevels,
  },
  {
    id: 2_8_3,
    path: "/workspace-module-answer-levels",
    type: "private",
    exact: true,
    component: AnswerLevels,
  },
  {
    id: 2_8_4,
    path: "/workspace-module-question-levels",
    type: "private",
    exact: true,
    component: QuestionLevels,
  },
  {
    id: 2_8_5,
    path: "/workspace-module-helpful-levels",
    type: "private",
    exact: true,
    component: HelpfulLevels,
  },
  {
    id: 2_8_6,
    path: "/workspace-module-transition-levels",
    type: "private",
    exact: true,
    component: TransitionLevels,
  },
  {
    id: 2_8_6,
    path: "/workspace-module-tiebreakers",
    type: "private",
    exact: true,
    component: WorkspaceModuleTiebreakers,
  },
  {
    id: 2_10,
    path: "/admin-manage",
    type: "private",
    exact: true,
    component: AdminManageSetting,
  },
  {
    id: 2_11,
    path: "/message-by-color",
    type: "private",
    exact: true,
    component: MessageByColor,
  },
  {
    id: 2_12,
    path: "/video-link",
    type: "private",
    exact: true,
    component: VideoLink,
  },
  {
    id: 2_13,
    path: "/music-link",
    type: "private",
    exact: true,
    component: MusicLink,
  },
  {
    id: 2_14,
    path: "/export-template",
    type: "private",
    exact: true,
    component: ExportTemplate,
  },
  {
    id: 2_15,
    path: "/milestone-config",
    type: "private",
    exact: true,
    component: MilestoneConfig,
  },

  {
    id: "no_match",
    path: "*",
    type: "private",
    component: NoMatch,
  },
];
