import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";
import history from "@Services/History";
import {API_RES_MESSAGE} from "@Services/Constants"

class MessageByColorStore extends FetchBase { 
  @observable ListOfMessageByColor= [];
  @observable fromDate = null;
  @observable toDate = null;
  @observable btnLoading = false;
 
  @action.bound
  fetchAllListOfMessageColor = async (fromDate, toDate) => {
    try {
      this.fromDate = fromDate;
      this.toDate = toDate;
      const res = await this._get(
        `admin/message-by-color`
      );
      this.ListOfMessageByColor = res.data.data;
    } catch (e) {
      console.log("Error Store:", e);
    }
  };

  @action.bound
  addNewMessageByColor = async (formData) => {
    try {
      const response = await this._post(
        `admin/message-by-color`,
        formData
      );
      toastr.success(response.data.message);

      return response?.status
    } catch (e) { 
      toastr.error(API_RES_MESSAGE?.messageByColorResFail);
      console.log("Error store :", e);
    }
  };

  @action.bound
  updateMessageByColor = async (formData,id) => {
    try {
      const response = await this._patch(
        `admin/message-by-color/${id}`,
        formData
      );
      toastr.success(response.data.message);

      return response?.status
    } catch (e) {
      toastr.error(API_RES_MESSAGE?.messageByColorResFail);

      console.log("Error store :", e);
    }
  };

  @action.bound
  deleteMessageColor = async (formData) => {
    try {
      const response = await this._delete(
        `admin/message-by-color/${formData._id}`,
        { data: { payload: formData } }
      );
      toastr.success(response?.data?.message); 
      return response?.status
    } catch (e) {
      toastr.error("Sorry, Please try again");
      console.log("Error store :", e);
    }
  };
  
}

export default MessageByColorStore;
