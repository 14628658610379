import moment from "moment";

export const arrayOfObjectToObject = (list = [], name = "", existObject) => {
  let option = existObject ? [{ ...existObject }] : [{}];

  if (list?.length) {
    list.map((text) => {
      option = [
        ...option,
        {
          id: text._id,
          name: text[name],
          text: text[name],
          value: text[name],
        },
      ];
    });
    return option;
  } else {
    return [];
  }
};

export const dateFormate = (value, format = "MM/DD/YYYY") => {
  return moment(value).format(format);
};

export const getDuration = (file) => {
  return new Promise((resolve) => {
    var objectURL = URL.createObjectURL(file);
    var mySound = new Audio([objectURL]);
    mySound.addEventListener(
      "canplaythrough",
      () => {
        URL.revokeObjectURL(objectURL);
        resolve({
          file,
          duration: mySound.duration,
        });
      },
      false
    );
  });
};

export const formatTimeByDuration = (duration) => {
  let d = duration;
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);
  console.log("s?.length", s);
  return `${m > 9 ? m : `0${m}`}:${s > 9 ? s : `0${s}`}`;
};

export const generateFormData = (values = {}) => {
  let newForm = new window.FormData();
  for (let key of Object.keys(values)) {
    if (![undefined, null].includes(values[key])) {
      if (Array.isArray(values[key])) {
        for (let item of values[key]) {
          newForm.append(key, item);
        }
      } else {
        newForm.append(key, values[key]);
      }
    }
  }
  return newForm;
};
