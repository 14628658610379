import { observable, action, computed } from "mobx";
import FetchBase from "@Services/FetchBase";
import toastr from "@Services/Message";
import history from "@Services/History"; 

class DashboardStore extends FetchBase {
  @observable locations = [];
  @observable usersData = {};
  @observable isFetchingUserData = false;

  @action.bound
  fetchLocationDetails = async () => {
    try {
      const response = await this._get("/admin/geo-location");
      this.locations = response.data.data;
    } catch (e) {
      console.log("Error store: ", e);
    }
  };

  @action.bound
  fetchUserInfo = async (category, color) => {
    this.isFetchingUserData = true;
    try {
      const response = await this._get(
        `/admin/dashboard?category=${category}&color=${color}`
      );
      this.usersData = response.data.data;
      this.isFetchingUserData = false;
    } catch (e) {
      console.log("Error in fetching data: ", e);
      this.isFetchingUserData = false;
      toastr.error("Error occured in fetching user data!");
    }
  };
}

export default DashboardStore;
